<template>
  <div class="content__body">
    <app-cells position="between">
      <v-select
        @search="onFilterSearch"
        noDrop
        label="id"
        placeholder="Поиск по названию"
        class="select select--minw"
      />
      <router-link :to="{ name: 'event-create' }" class="button button--mini">Создать мероприятие</router-link>
    </app-cells>
    <div class="events">
      <router-link
        :to="{ name: 'event-detail', params: { id: event.id } }"
        v-for="event in events"
        :key="event.id"
        class="events__item"
      >
        <div class="events__body">
          <div class="events__title">{{ event.name }}</div>
          <div class="events__info">
            <div v-if="event.date_start" class="events__point">
              <img src="@/assets/img/team/calendar.svg" alt="Calendar icon">
              <span>{{ event.date_start }} - {{ event.date_end }}</span>
            </div>
            <div class="events__point">
              <img v-if="event.participation_form_name === 'Очное'" src="@/assets/img/events/offline-icon.svg" alt="Participation icon">
              <img v-if="event.participation_form_name === 'Заочное'" src="@/assets/img/events/online-icon.svg" alt="Participation icon">
              <img v-if="event.participation_form_name === 'Очно-заочное'" src="@/assets/img/events/on-off-icon.svg" alt="Participation icon">
              <span>{{ event.participation_form_name }}</span>
            </div>
            <div v-if="event.region_text" class="events__point">
              <img src="@/assets/img/team/calendar.svg" alt="Calendar icon">
              <span>{{ event.region_text }}</span>
            </div>
          </div>
        </div>
        <div class="events__pic">
          <img v-if="!event.crop_url" src="@/assets/img/events/event-slug.svg" alt="Event slug">
          <img v-else :src="event.crop_url" alt="Event image">
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash'

export default {
  name: 'EventsManageList',
  data() {
    return {
      events: []
    }
  },
  mounted() {
    this.fetchItems()
  },
  methods: {
    fetchItems(search = '') {
      this.$store.dispatch('events/GET_LIST', search).then(response => { this.events = response.data.results })
    },
    onFilterSearch(search, loading) {
      loading(true)
      this.searchFilter(loading, search, this)
    },
    searchFilter: debounce((loading, search, vm) => {
      vm.fetchItems(search)
      loading(false)
    }, 350),
  }
}
</script>
